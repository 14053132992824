import React, { useEffect, useState } from "react";
import Startermenu from "../Allmenus/FirstMenu/Startermenu";
import Offer from "../Allmenus/FirstMenu/Offers";
import Reservation from "../Allmenus/FirstMenu/Reservation";
import Bredcom from "../Bredcrumb/Main";
import Img from "../../assets/images/background/menu_banner.jpg";
import Menusection from "../Menhome/Menusection";
import { retriveCategories } from "../../services/CategoryService";
import { toast } from "react-toastify";
import _ from "lodash";

function Menuone() {
  const [categoryData, setcategoryData] = useState([]);
  const [firstIndexData, setfirstIndexData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await retriveCategories()
      .then((response) => {
        let dataModifier = [];
        let fourArray = [];

        for (let [i, a] of response?.data?.data?.elements?.entries()) {
          if (fourArray?.length < 4) {
            fourArray.push(a);
            if (response?.data?.data?.elements?.length === i + 1) {
              dataModifier.push(fourArray);
              fourArray = [];
            }
          } else {
            dataModifier.push(fourArray);
            fourArray = [];
            fourArray.push(a);
          }
        }

        arrayModifier(dataModifier);
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error?.response?.data?.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const arrayModifier = (array) => {
    let firstIndex = [];

    for (let b of array) {
      firstIndex?.push(b[0]);
    }
    setfirstIndexData(firstIndex);
    setcategoryData(array);
  };

  return (
    <>
      <Bredcom subtitle="Our Menu" title="DELICIOUS & AMAZING" Img={Img} />
      {/* <Startermenu />
      <Offer /> */}
      {!_.isEmpty(categoryData) && (
        <Menusection data={categoryData} indexData={firstIndexData} />
      )}
    </>
  );
}

export default Menuone;
