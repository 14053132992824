import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import bgone from "../../assets/images/background/menu_animation.png";
import Bgtwo from "../../assets/images/background/menu_animation.png";
import five from "../../assets/images/food-image/category-vegetarian-kabab.jpg";
import six from "../../assets/images/resource/menu-image-6.png";
import seven from "../../assets/images/resource/menu-image-7.png";
import eight from "../../assets/images/resource/menu-image-8.png";
import nine from "../../assets/images/resource/menu-image-9.png";
import ten from "../../assets/images/resource/menu-image-10.png";
import { toast } from "react-toastify";
import { retriveCategoriesDetails } from "../../services/CategoryService";
import _ from "lodash";
import {
  addItemToWishlist,
  wishlistClearApi,
  addItemToCart,
} from "../../services/ItemService";
import { isLogin } from "../../utils/constant";
import ConfirmationPopupModal from "../ConfirmationPopupModal/Main";
import Loader from "../Loader/Main";

function Menusection({ data, indexData }) {
  const [tabMenu, tabActive] = useState({ a: true });
  // const [countOfAPICalling, setcountOfAPICalling] = useState(1);
  const [itemData, setitemData] = useState([]);
  const [firstIndexData, setfirstIndexData] = useState(indexData);
  const [apicalledIds, setapicalledIds] = useState([]);
  const initialized = useRef(false);

  const navigate = useNavigate();

  let countOfAPICalling = 0;

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (!_.isEmpty(firstIndexData[0]?.id)) {
        getCategoryData(firstIndexData[0]?.id);
      }
    }
  }, []);

  console.log(firstIndexData, "<>", apicalledIds);

  const getCategoryData = async (id) => {
    await retriveCategoriesDetails(id)
      .then((response) => {
        // if (countOfAPICalling > firstIndexData?.length + 1) {
        let arr = apicalledIds;
        arr.push({ id, countOfAPICalling });
        setapicalledIds(arr);
        apiCallFun(response?.data?.data?.elements);
        // }
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const getCategoryDataSingleTime = async (id, index, num) => {
    await retriveCategoriesDetails(id)
      .then((response) => {
        toggleClicked(response?.data?.data?.elements, index, num);
        toggleChange(response?.data?.data?.elements, index);
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const apiCallFun = (res) => {
    let array = itemData;

    array.push(res);
    setitemData([...array]);
    if (countOfAPICalling < firstIndexData?.length) {
      if (firstIndexData[countOfAPICalling + 1]?.id) {
        getCategoryData(
          firstIndexData[Number(Number(countOfAPICalling) + 1)]?.id
        );
      }
      countOfAPICalling = countOfAPICalling + 1;
    }
  };

  const toggleChange = (data, index) => {
    let array = [];
    for (let [i, a] of itemData.entries()) {
      if (i === index) {
        array.push(data);
      }
      array.push(a);
    }
    setitemData(array);
  };

  const toggleClicked = (arr, index, num) => {
    let array = [];
    for (let [i, a] of firstIndexData?.entries()) {
      if (i === index) {
        array?.push(data[index][num]);
      } else {
        array.push(a);
      }
    }

    setfirstIndexData(array);
  };

  const wishListToggle = async (a, isLike, index) => {
    let formdata = new FormData();
    formdata.append("item_id", a?.id);

    if (isLike) {
      await wishlistClearApi(formdata)
        .then((response) => {
          let array = [];
          for (let [i, ar] of itemData?.entries()) {
            if (i === index) {
              let arr = [];
              for (let b of ar) {
                let obj = b;

                if (b?.id === a?.id) {
                  arr?.push({ ...b, is_wishlist: false });
                } else {
                  arr?.push(obj);
                }
              }
              array.push(arr);
            } else {
              array.push(ar);
            }
          }
          setitemData(array);

          toast(response?.data?.message, { type: "success" });
        })
        .catch((error) => {
          if (error?.response?.status == 422)
            toast(error.response.data.error, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    } else {
      await addItemToWishlist(formdata)
        .then((response) => {
          let array = [];
          for (let [i, ar] of itemData?.entries()) {
            if (i === index) {
              let arr = [];
              for (let b of ar) {
                if (b?.id === a?.id) {
                  arr?.push({ ...b, is_wishlist: true });
                } else {
                  arr?.push(b);
                }
              }
              array.push(arr);
            } else {
              array.push(ar);
            }
          }
          setitemData(array);

          toast(response?.data?.message, { type: "success" });
        })
        .catch((error) => {
          if (error?.response?.status == 422)
            toast(error.response.data.error, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    }
  };

  const cartValueChange = async (value, id, index) => {
    if (isLogin()) {
      if (value > 0 || value === 0 || value === "") {
        let formdata = new FormData();
        formdata.append("item_id", id);
        formdata.append("item_qty", value || 0);

        await addItemToCart(formdata)
          .then((response) => {
            let array = [];
            for (let [i, a] of itemData?.entries()) {
              // if (i <= firstIndexData?.length) {
              if (i === index) {
                let arr = [];
                for (let b of a) {
                  let obj = b;
                  if (b?.id === id) {
                    arr?.push({ ...b, is_cart: value || 0 });
                  } else {
                    arr?.push(obj);
                  }
                }
                array.push(arr);
              } else {
                array.push(a);
              }
              // }
            }

            setitemData(array);
            toast(response?.data?.message, { type: "success" });
          })
          .catch((error) => {
            if (error?.response?.status == 422)
              toast(error.response.data.error, { type: "error" });
            else if (error?.response?.status == 500)
              toast(error.response.data.message, { type: "error" });
            else toast("Something went wrong", { type: "error" });
          });
      }
    } else {
      toast("Please Login to add item in cart", {
        type: "error",
      });
    }
  };

  const orderDetails = (i, data) => {
    let obj;
    for (let a of data) {
      for (let x of a) {
        if (x?.id === firstIndexData[i]?.id) {
          obj = x;
        }
      }
    }
    let str = obj?.name?.replace(/\s+/g, "-").toLowerCase();
    navigate(`/menuone/${str}/${obj?.id}`);
  };

  return (
    <>
      <section className="menu-section">
        <div className="left-bg">
          <img src={bgone} alt="" title="" />
        </div>

        <div className="right-bg">
          <img src={Bgtwo} alt="" title="" />
        </div>

        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>Special selection</span>
            </div>

            <div className="pattern-image">
              <img
                src={require("../../assets/images/icons/separator.svg").default}
                alt="mySvgImage"
              />
            </div>

            <h2>Delicious Menu</h2>
          </div>
          {!_.isEmpty(data) && !_.isEmpty(itemData) ? (
            <>
              {data?.map((x, i) => {
                return (
                  <div
                    className="tabs-box menu-tabs "
                    style={{ marginTop: "100px" }}
                  >
                    <div className="buttons">
                      <ul className="tab-buttons clearfix">
                        {x?.map((a, num) => (
                          <li
                            className={`tab-btn ${
                              a?.id === firstIndexData[i]?.id && "active-btn"
                            }`}
                            // active-btn
                            onClick={() => {
                              getCategoryDataSingleTime(a?.id, i, num);
                              // tabActive({ a: true })
                            }}
                            data-tab="#tab-1"
                          >
                            <span>{a?.name}</span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="tabs-content">
                      <div
                        className={`tab 
                    
                    ${tabMenu.a && "active-tab"}`}
                        id="tab-1"
                      >
                        <div className="row clearfix">
                          <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                              {itemData[i]?.map((a, index) => {
                                let isTrue = index < 3;

                                return (
                                  <>
                                    {isTrue && (
                                      <div className="dish-block">
                                        <div className="inner-box">
                                          <div className="dish-image">
                                            <Link to="#">
                                              <img src={five} alt="" />
                                            </Link>
                                          </div>
                                          <div className="title clearfix">
                                            <div className="ttl clearfix">
                                              <h5>
                                                <Link to="#">{a?.name} </Link>
                                                <i
                                                  onClick={() => {
                                                    if (!isLogin()) {
                                                      toast(
                                                        "Please Login to add in favourites",
                                                        {
                                                          type: "error",
                                                        }
                                                      );
                                                    } else {
                                                      wishListToggle(
                                                        a,
                                                        a?.is_wishlist,
                                                        i
                                                      );
                                                    }
                                                  }}
                                                  className={`icon far ${
                                                    a?.is_wishlist && "fa-solid"
                                                  } fa-heart ml-2 `}
                                                  style={{
                                                    color: "var(--main-color)",
                                                    fontSize: "20px",
                                                  }}
                                                ></i>
                                              </h5>
                                            </div>
                                            <div className="price">
                                              <span>${a?.price}</span>
                                            </div>
                                          </div>

                                          <div style={{ display: "flex" }}>
                                            <div className="text desc">
                                              {/* Tomatoes, green bell pepper, sliced
                                          cucumber onion, olives, and feta
                                          cheese. */}
                                            </div>
                                            <div
                                              className="add-to-cart mt-2 mb-2"
                                              style={{
                                                display: "flex",
                                                // justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              <i
                                                className="icon far fa-minus"
                                                onClick={() => {
                                                  cartValueChange(
                                                    Number(
                                                      Number(a?.is_cart) - 1
                                                    ),
                                                    a?.id,
                                                    i
                                                  );
                                                }}
                                              ></i>
                                              <input
                                                className="input-cart"
                                                value={a?.is_cart}
                                                type="number"
                                                onChange={(e) => {
                                                  cartValueChange(
                                                    e?.target?.value,
                                                    a?.id,
                                                    i
                                                  );
                                                }}
                                              />
                                              <i
                                                className="icon far fa-plus"
                                                onClick={() => {
                                                  cartValueChange(
                                                    Number(
                                                      Number(a?.is_cart) + 1
                                                    ),
                                                    a?.id,
                                                    i
                                                  );
                                                }}
                                              ></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                );
                              })}

                              {/* <div className="dish-block">
                            <div className="inner-box">
                              <div className="dish-image">
                                <Link to="#">
                                  <img src={six} alt="" />
                                </Link>
                              </div>
                              <div className="title clearfix">
                                <div className="ttl clearfix">
                                  <h5>
                                    <Link to="#">
                                      Lasagne{" "}
                                      <span className="s-info">SEASONAL</span>
                                    </Link>
                                  </h5>
                                </div>
                                <div className="price">
                                  <span>$40.00</span>
                                </div>
                              </div>
                              <div className="text desc">
                                <Link to="#">
                                  Vegetables, cheeses, ground meats, tomato
                                  sauce, seasonings and spices
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="dish-block">
                            <div className="inner-box">
                              <div className="dish-image">
                                <Link to="#">
                                  <img src={seven} alt="" />
                                </Link>
                              </div>
                              <div className="title clearfix">
                                <div className="ttl clearfix">
                                  <h5>
                                    <Link to="#">Butternut Pumpkin</Link>
                                  </h5>
                                </div>
                                <div className="price">
                                  <span>$10.00</span>
                                </div>
                              </div>
                              <div className="text desc">
                                <Link to="#">
                                  Typesetting industry lorem Lorem Ipsum is
                                  simply dummy text of the priand.
                                </Link>
                              </div>
                            </div>
                          </div> */}
                            </div>
                          </div>
                          <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                              {itemData[i]?.map((a, index) => {
                                let isTrue = index > 2 && index < 6;
                                console.log(a, "<<<");
                                // let isTrue = true;
                                return (
                                  <>
                                    {isTrue && (
                                      <div className="dish-block">
                                        <div className="inner-box">
                                          <div className="dish-image">
                                            <Link to="#">
                                              <img src={five} alt="" />
                                            </Link>
                                          </div>
                                          <div className="title clearfix">
                                            <div className="ttl clearfix">
                                              <h5>
                                                <Link to="#">
                                                  {a?.name}{" "}
                                                  {/* <span className="s-info">
                                                NEW
                                              </span> */}
                                                  <i
                                                    onClick={() => {
                                                      if (!isLogin()) {
                                                        toast(
                                                          "Please Login to add in favourites",
                                                          {
                                                            type: "error",
                                                          }
                                                        );
                                                      } else {
                                                        wishListToggle(
                                                          a,
                                                          a?.is_wishlist,
                                                          i
                                                        );
                                                      }
                                                    }}
                                                    className={`icon far ${
                                                      a?.is_wishlist &&
                                                      "fa-solid"
                                                    } fa-heart ml-2 `}
                                                    style={{
                                                      color:
                                                        "var(--main-color)",
                                                      fontSize: "20px",
                                                    }}
                                                  ></i>
                                                </Link>
                                              </h5>
                                            </div>
                                            <div className="price">
                                              <span>${a?.price}</span>
                                            </div>
                                          </div>
                                          <div style={{ display: "flex" }}>
                                            <div className="text desc">
                                              {/* Tomatoes, green bell pepper, sliced
                                          cucumber onion, olives, and feta
                                          cheese. */}
                                            </div>
                                            <div
                                              className="add-to-cart mt-2 mb-2"
                                              style={{
                                                display: "flex",
                                                // justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              <i
                                                className="icon far fa-minus"
                                                onClick={() => {
                                                  cartValueChange(
                                                    Number(
                                                      Number(a?.is_cart) - 1
                                                    ),
                                                    a?.id,
                                                    i
                                                  );
                                                }}
                                              ></i>
                                              <input
                                                className="input-cart"
                                                value={a?.is_cart}
                                                type="number"
                                                onChange={(e) => {
                                                  cartValueChange(
                                                    e?.target?.value,
                                                    a?.id,
                                                    i
                                                  );
                                                }}
                                              />
                                              <i
                                                className="icon far fa-plus"
                                                onClick={() => {
                                                  cartValueChange(
                                                    Number(
                                                      Number(a?.is_cart) + 1
                                                    ),
                                                    a?.id,
                                                    i
                                                  );
                                                }}
                                              ></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            color: "var(--main-color)",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            orderDetails(i, data);
                          }}
                        >
                          View More
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div style={{ marginTop: "100px" }}>
              <Loader />
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Menusection;
