import React from "react";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import { isFloat } from "../../utils/constant";

const Pagination = ({ total, page, setpage }) => {
  //   console.log(13 / 10, "<<total", isFloat(13 / 10), Math.ceil(11 / 10));
  let array = [];
  for (let index = 0; index < Math.ceil(total / 10); index++) {
    array.push(index + 1);
  }
  console.log(array);
  return (
    <nav
      aria-label="Page navigation example"
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "50px",
      }}
    >
      <MDBPagination className="mb-0 " style={{ gap: "30px" }}>
        <MDBPaginationItem
          style={{ cursor: `${page - 1 === 0 ? "not-allowed" : "pointer"}` }}
          onClick={() => {
            if (page - 1 !== 0) {
              setpage(page - 1);
            }
          }}
        >
          <span>«</span>
        </MDBPaginationItem>
        {array?.map((x, i) => (
          <span
            className={`${page === i + 1 && "circle"}`}
            style={{ cursor: "pointer" }}
            onClick={() => setpage(x)}
          >
            {x}
          </span>
        ))}

        <MDBPaginationItem>
          <span
            style={{
              cursor: `${page + 1 > array.length ? "not-allowed" : "pointer"}`,
            }}
            onClick={() => {
              if (page + 1 <= array.length) {
                setpage(page + 1);
              }
            }}
          >
            »
          </span>
        </MDBPaginationItem>
      </MDBPagination>
    </nav>
  );
};

export default Pagination;
