import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Backgroundimage from "../../../assets/images/background/image-8.jpg";
import bg19 from "../../../assets/images/background/bg-19.png";
import bg20 from "../../../assets/images/background/bg-20.png";
import img8 from "../../../assets/images/background/image-8.jpg";
import badgeone from "../../../assets/images/resource/badge-2.png";
import five from "../../../assets/images/food-image/category-vegetarian-kabab.jpg";
import { isLogin } from "../../../utils/constant";
import { toast } from "react-toastify";
import { retriveCategoriesDetails } from "../../../services/CategoryService";
import { addItemToCart, addItemToWishlist, wishlistClearApi } from "../../../services/ItemService";
import Loader from "../../Loader/Main";
import _ from "lodash";


function Specialmenu({id}) {
  const [data, setdata] = useState([])

  useEffect(() => {
    
  getCategoryDataSingleTime(id)
  
  }, [])
  

   const getCategoryDataSingleTime = async (id) => {
     await retriveCategoriesDetails(id)
       .then((response) => {
        setdata(response?.data?.data?.elements);
        })
       .catch((error) => {
         if (error?.response?.status == 422)
           toast(error.response.data.error, { type: "error" });
         else if (error?.response?.status == 500)
           toast(error.response.data.message, { type: "error" });
         else toast("Something went wrong", { type: "error" });
       });
   };


   const wishListToggle = async (a, isLike) => {
     let formdata = new FormData();
     formdata.append("item_id", a?.id);

     if (isLike) {
       await wishlistClearApi(formdata)
         .then((response) => {
           let array = [];
         
           for(let x of data){
            let obj=x
            if(x?.id===a?.id){
              obj = { ...x, is_wishlist :false};

            }
            array?.push(obj)
           }

           setdata(array);

           toast(response?.data?.message, { type: "success" });
         })
         .catch((error) => {
           if (error?.response?.status == 422)
             toast(error.response.data.error, { type: "error" });
           else if (error?.response?.status == 500)
             toast(error.response.data.message, { type: "error" });
           else toast("Something went wrong", { type: "error" });
         });
     } else {
       await addItemToWishlist(formdata)
         .then((response) => {
           let array = [];
            for (let x of data) {
              let obj = x;
              if (x?.id === a?.id) {
                obj = { ...x, is_wishlist: true };
              }
              array?.push(obj);
            }

            setdata(array);

           toast(response?.data?.message, { type: "success" });
         })
         .catch((error) => {
           if (error?.response?.status == 422)
             toast(error.response.data.error, { type: "error" });
           else if (error?.response?.status == 500)
             toast(error.response.data.message, { type: "error" });
           else toast("Something went wrong", { type: "error" });
         });
     }
   };
const cartValueChange = async (value, id, index) => {
  if (isLogin()) {
    if (value > 0 || value === 0 || value === "") {
      let formdata = new FormData();
      formdata.append("item_id", id);
      formdata.append("item_qty", value || 0);

      await addItemToCart(formdata)
        .then((response) => {
          let array = [];
          

          for(let x of data){
            let obj=x
            if(id===x?.id){
              obj = { ...x, is_cart: value || 0 };
            }
            array?.push(obj)
          }

          setdata(array);
          toast(response?.data?.message, { type: "success" });
        })
        .catch((error) => {
          if (error?.response?.status == 422)
            toast(error.response.data.error, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    }
  } else {
    toast("Please Login to add item in cart", {
      type: "error",
    });
  }
};


  return (
    <>
      <section className="special-offer-two">
        <div className="left-bg">
          <img src={bg20} alt="" title="" />
        </div>
        <div className="right-bg">
          <img src={bg19} alt="" title="" />
        </div>
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">{/* <span>special offer</span> */}</div>
            <div className="pattern-image">
              <img
                src={
                  require("../../../assets/images/icons/separator.svg").default
                }
                alt=""
                title=""
              />
            </div>
            {/* <h2>Your Cart</h2> */}
          </div>
         {!_.isEmpty(data)?
          <div className="row clearfix">
            {data?.map((a) => (
              <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div
                  className="inner-box wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="0ms"
                >
                  <div className="image">
                    <Link to="#">
                      <img src={five} alt="" />
                    </Link>
                  </div>
                  <h5>
                    <Link to="/menu">{a?.name}</Link>
                    <i
                      onClick={() => {
                        if (!isLogin()) {
                          toast("Please Login to add in favourites", {
                            type: "error",
                          });
                        } else {
                          wishListToggle(a, a?.is_wishlist);
                        }
                      }}
                      className={`icon far ${
                        a?.is_wishlist && "fa-solid"
                      } fa-heart ml-2 `}
                      style={{
                        color: "var(--main-color)",
                        fontSize: "20px",
                      }}
                    ></i>
                  </h5>
                  <div className="text desc">
                    {/* Avocados with crab meat, red onion, crab salad red bell
                    pepper... */}
                  </div>

                  <div className="price ">
                    <div
                      className="add-to-cart mr-3 mb-3"
                      style={{
                        // display: "flex",
                        // justifyContent: "center",
                        // alignItems: "center",
                      }}
                    >
                      <i
                        className="icon far fa-minus"
                        onClick={() => {
                          cartValueChange(
                            Number(Number(a?.is_cart) - 1),
                            a?.id,
                            
                          );
                        }}
                      ></i>
                      <input
                        className="input-cart"
                        value={a?.is_cart}
                        type="number"
                        onChange={(e) => {
                          cartValueChange(e?.target?.value, a?.id);
                        }}
                      />
                      <i
                        className="icon far fa-plus"
                        onClick={() => {
                          cartValueChange(
                            Number(Number(a?.is_cart) + 1),
                            a?.id,
                            
                          );
                        }}
                      ></i>
                    </div>
                    ${a?.price}
                  </div>
                </div>
              </div>
            ))}
          </div>
          :
          
          <Loader />
          }
        </div>
      </section>
    </>
  );
}

export default Specialmenu;
