import React from "react";
import { Link } from "react-router-dom";
import bg3 from "../../assets/images/food-image/category-appetizers.jpg";
import badge1 from "../../assets/images/resource/badge-1.png";
import img1 from "../../assets/images/food-image/category-non-vegetarian.jpg";
import img2 from "../../assets/images/food-image/category-vegetarian.jpg";

function Story() {
  return (
    <>
      <section className="story-section">
        <div className="left-bg">
          <img src={bg3} alt="" title="" />
        </div>
        <div className="auto-container">
          <div className="row clearfix">
            <div className="text-col col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <div
                className="inner wow fadeInLeft"
                data-wow-duration="1500ms"
                data-wow-delay="0ms"
              >
                <div className="title-box centered">
                  <div className="subtitle">
                    <span> Our story </span>
                  </div>
                  <div className="pattern-image">
                    <img
                      src={
                        require("../../assets/images/icons/separator.svg")
                          .default
                      }
                      alt="mySvgImage"
                    />
                  </div>
                  <h2>Every Flavor Tells a Story</h2>
                  <div className="text">
                   At Spice Grill in Parsippany we create traditional Indian dishes in a unique and welcoming setting. Only the finest and freshest ingredients and classic Indian spices and herbs are used to prepare all of our sumptuous dishes. Enjoy our large variety of vegetarian and non-vegetarian Indian dishes, our luscious kababs, and a huge assortment of appetizers, breads, and desserts. We invite you to join us in Parsippany for our lunch buffet, Sunday dinner buffet or for our dinner menu. We welcome you to become part of our story
                  </div>
                </div>
                <div className="booking-info">
                  <div className="bk-title">Book Through Call</div>
                  <div className="bk-no">
                    <Link to="tel:+80-400-123456">973-882-4646</Link>
                  </div>
                  <div className="link-box">
                    <Link
                      to="/about"
                      className="theme-btn btn-style-two clearfix"
                    >
                      <span className="btn-wrap">
                        <span className="text-one">Read More</span>
                        <span className="text-two">Read More</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="image-col col-xl-7 col-lg-7 col-md-12 col-sm-12">
              <div
                className="inner wow fadeInRight"
                data-wow-duration="1500ms"
                data-wow-delay="0ms"
              >
                {/* <div className="round-stamp">
                  <img src={badge1} alt="" />
                </div> */}
                <div className="images parallax-scene-1">
                  <div className="image" data-depth="0.15">
                    <img src={img1} alt="" />
                  </div>
                  <div className="image" data-depth="0.30">
                    <img src={img2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Story;
