import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { object, ref, string } from "yup";
import {
  createAccount,
  getCountryDetail,
  getStateDetail,
} from "../../services/AuthService";
import _ from "lodash";

const Main = () => {
  const [isPassword, setisPassword] = useState(false);
  const [isConfirmPassword, setisConfirmPassword] = useState(false);
  const [stateData, setstateData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [countryData, setcountryData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getStateDetails(1);
    // getCountryDetails();
  }, []);

  const getCountryDetails = async () => {
    await getCountryDetail()
      .then((response) => {
        setcountryData(response?.data?.data?.elements);
      })
      .catch((error) => {
        setisLoading(false);
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const getStateDetails = async (id) => {
    await getStateDetail(id)
      .then((response) => {
        setstateData(response?.data?.data?.elements);
      })
      .catch((error) => {
        setisLoading(false);
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const RegisterSchema = object().shape({
    first_name: string()
      .required("Please enter first name.")
      .trim("Please enter first name."),

    last_name: string()
      .required("Please enter last name.")
      .trim("Please enter last name."),

    phone_number: string()
      .required("Please enter contact number.")
      .trim("Please enter contact number."),

    email: string()
      .required("Please enter email.")
      .trim("Please enter email.")
      .email("Invalid Email Format. Please try again."),

    password: string()
      .required("Please enter password.")
      .trim("Please enter password."),

    confirm_password: string()
      .required("Please enter confirm password.")
      .trim("Please enter confirm password.")
      .oneOf(
        [ref("password")],
        "Password and confirm password does not match."
      ),

    country: string()
      .required("Please Select country.")
      .trim("Please Select country."),

    state: string()
      .required("Please Select state.")
      .trim("Please Select state."),

    city: string().required("Please enter city.").trim("Please enter city."),

    addreess: string()
      .required("Please enter addreess.")
      .trim("Please enter addreess."),

    postalcode: string()
      .required("Please enter postalcode.")
      .trim("Please enter postalcode."),
  });

  const { handleSubmit, handleBlur, handleChange, values, errors, touched } =
    useFormik({
      validationSchema: RegisterSchema,

      initialValues: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        password: "",
        confirm_password: "",
        postalcode: "",
        city: "",
        state: "",
        country: "1",
        addreess: "",
      },
      onSubmit: async (values, { resetForm }) => {
        let formdata = new FormData();
        formdata.append("first_name", values?.first_name);
        formdata.append("last_name", values?.last_name);
        formdata.append("email", values?.email);
        formdata.append("phone_number", values?.phone_number);
        formdata.append("password", values?.password);
        formdata.append("confirm_password", values?.confirm_password);
        formdata.append("postalcode", values?.postalcode);
        formdata.append("addreess", values?.addreess);
        formdata.append("city", values?.city);
        formdata.append("state", values?.state);
        formdata.append("country", values?.country);

        await createAccount(formdata)
          .then((response) => {
            setisLoading(false);
            toast("Customer Register Successfully", { type: "success" });
            navigate("/login");
          })
          .catch((error) => {
            setisLoading(false);
            if (error?.response?.status == 422)
              toast(error.response.data.error, { type: "error" });
            else if (error?.response?.status == 500)
              toast(error.response.data.message, { type: "error" });
            else toast("Something went wrong", { type: "error" });
          });
      },

      onReset: () => {},
    });

  return (
    <div className="auto-container" style={{ marginTop: "230px" }}>
      <div
        // className="c-page-form-box"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="loc-block col-lg-6 col-md-12 col-sm-12">
          <div className="title-box centered" style={{ marginBottom: "40px" }}>
            <h2>Register</h2>
          </div>
          <div className="default-form reservation-form">
            <form
              onSubmit={(e) => {
                e?.preventDefault();
                handleSubmit();
              }}
            >
              <div className=" row clearfix">
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="text"
                      name="first_name"
                      placeholder="First Name*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.first_name}
                      style={{
                        borderColor:
                          touched?.first_name && errors?.first_name && "red",
                      }}
                    />
                  </div>
                  {touched?.first_name && errors?.first_name && (
                    <p className="error-message">{errors.first_name}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="text"
                      name="last_name"
                      placeholder="Last Name*"
                      // required=""
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last_name}
                      style={{
                        borderColor:
                          touched?.last_name && errors?.last_name && "red",
                      }}
                    />
                  </div>
                  {touched?.last_name && errors?.last_name && (
                    <p className="error-message">{errors.last_name}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="text"
                      name="email"
                      placeholder="Email*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      style={{
                        borderColor: touched?.email && errors?.email && "red",
                      }}
                      // required=""
                    />
                  </div>
                  {touched?.email && errors?.email && (
                    <p className="error-message">{errors.email}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="text"
                      name="phone_number"
                      placeholder="Phone*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone_number}
                      style={{
                        borderColor:
                          touched?.phone_number &&
                          errors?.phone_number &&
                          "red",
                      }}
                      // required=""
                    />
                  </div>
                  {touched?.phone_number && errors?.phone_number && (
                    <p className="error-message">{errors.phone_number}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="">
                    <input
                      type={isPassword ? "text" : "password"}
                      name="password"
                      placeholder="Password*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      style={{
                        borderColor:
                          touched?.password && errors?.password && "red",
                      }}
                    />
                    <span
                      className={`arrow-icon far  ${
                        isPassword ? "fa-eye" : "fa-eye-slash"
                      } `}
                      style={{
                        cursor: "pointer",
                        zIndex: "50",
                        position: "absolute",
                        top: "18px",
                        right: "28px",
                      }}
                      onClick={() => {
                        setisPassword(!isPassword);
                      }}
                    ></span>
                  </div>
                  {touched?.password && errors?.password && (
                    <p className="error-message">{errors.password}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="">
                    <input
                      type={isConfirmPassword ? "text" : "password"}
                      name="confirm_password"
                      placeholder="Confirm Password*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirm_password}
                      style={{
                        borderColor:
                          touched?.confirm_password &&
                          errors?.confirm_password &&
                          "red",
                      }}
                    />
                    <span
                      className={`arrow-icon far  ${
                        isConfirmPassword ? "fa-eye" : "fa-eye-slash"
                      } `}
                      style={{
                        cursor: "pointer",
                        zIndex: "50",
                        position: "absolute",
                        top: "18px",
                        right: "28px",
                      }}
                      onClick={() => {
                        setisConfirmPassword(!isConfirmPassword);
                      }}
                    ></span>
                  </div>
                  {touched?.confirm_password && errors?.confirm_password && (
                    <p className="error-message">{errors.confirm_password}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <select
                      placeholder="Country"
                      name="country"
                      onBlur={handleBlur}
                      disabled={true}
                      value={values?.country}
                      style={{
                        borderColor:
                          touched?.country && errors?.country && "red",
                      }}
                      onChange={(e) => {
                        handleChange(e);
                        // getStateDetails(e?.target?.value);
                      }}
                    >
                      <option value="">USA</option>
                      {countryData?.map((x) => (
                        <option value={x?.id}>{x?.country_name}</option>
                      ))}
                    </select>
                    <span className="arrow-icon far fa-angle-down"></span>
                  </div>
                  {touched?.country && errors?.country && (
                    <p className="error-message">{errors?.country}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <select
                      placeholder="State"
                      name="state"
                      onBlur={handleBlur}
                      value={values?.state}
                      style={{
                        borderColor: touched?.state && errors?.state && "red",
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <option value="">State</option>
                      {stateData?.map((x) => (
                        <option value={x?.id}>{x?.state_name}</option>
                      ))}
                      {_.isEmpty(stateData) && (
                        <option value="">Loading...</option>
                      )}
                    </select>
                    <span className="arrow-icon far fa-angle-down"></span>
                  </div>
                  {touched?.state && errors?.state && (
                    <p className="error-message">{errors?.state}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="text"
                      name="city"
                      placeholder="City* "
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.city}
                      style={{
                        borderColor: touched?.city && errors?.city && "red",
                      }}
                    />
                    {/* <span className="arrow-icon far fa-angle-down"></span> */}
                  </div>
                  {touched?.city && errors?.city && (
                    <p className="error-message">{errors?.city}</p>
                  )}
                </div>

                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="number"
                      name="postalcode"
                      placeholder="Zip "
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.postalcode}
                      style={{
                        borderColor:
                          touched?.postalcode && errors?.postalcode && "red",
                      }}
                    />
                  </div>
                  {touched?.postalcode && errors?.postalcode && (
                    <p className="error-message">{errors.postalcode}</p>
                  )}
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 ">
                  <div className="field-inner">
                    <textarea
                      name="addreess"
                      placeholder="addreess"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{
                        borderColor:
                          touched?.addreess && errors?.addreess && "red",
                      }}
                      value={values.addreess}
                    ></textarea>
                  </div>
                  {touched?.addreess && errors?.addreess && (
                    <p className="error-message">{errors.addreess}</p>
                  )}
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="theme-btn btn-style-one clearfix"
                  >
                    <span className="btn-wrap">
                      <span className="text-one">Register</span>
                      <span className="text-two">Register</span>
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
