import { MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getOrderDetails } from "../../services/AuthService";
import OrderCart from "./OrderCart";
import Pagination from "../Pagination/Main";

const OrderHistory = () => {
  const [data, setdata] = useState([]);
  const [page, setpage] = useState(1);
  const [index, setindex] = useState(null);
  const [toggle, settoggle] = useState(false);
  const [total, settotal] = useState(null);

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    await getOrderDetails(page)
      .then((response) => {
        settotal(response?.data?.data?.ordercount[0]?.total);
        setdata(response?.data?.data?.orders);
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error?.response?.data?.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  console.log(toggle, "<<<", index);

  return (
    <div className="auto-container" style={{ marginTop: "230px" }}>
      <div
        // className="c-page-form-box"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="loc-block col-lg-6 col-md-12 col-sm-12">
          <div className="title-box centered" style={{ marginBottom: "40px" }}>
            <h2>Order History</h2>
          </div>
        </div>
      </div>
      {!toggle ? (
        <MDBTable className="mb-5">
          <MDBTableHead dark>
            <tr>
              <th scope="col">Sr.</th>
              <th scope="col">Date</th>
              <th scope="col">Sub Total</th>
              <th scope="col">Tax</th>
              <th scope="col">Grand Total</th>
              <th scope="col">Actions</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {data?.map((x, i) => (
              <tr>
                <th scope="row" className="text-white">
                  {i + 1}
                </th>
                <td className="text-white">{x?.created_at}</td>
                <td className="text-white">{x?.subtotal}</td>
                <td className="text-white">{x?.total_tax}</td>
                <td className="text-white ">{x?.total_payment}</td>
                <td>
                  <span
                    style={{
                      color: "var(--main-color)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setindex(i);
                      settoggle(true);
                    }}
                  >
                    View
                  </span>
                </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      ) : (
        <OrderCart obj={data[index]} toggle={toggle} settoggle={settoggle} />
      )}
      {!toggle && <Pagination total={total} page={page} setpage={setpage} />}
    </div>
  );
};

export default OrderHistory;
