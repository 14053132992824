import { MDBCardBody, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { orderFail } from "../../services/ItemService";

const FailPage = () => {
  let order_id = localStorage.getItem("order_id");

  useEffect(() => {
    if (!order_id) {
      navigate(-1, { replace: true });
    } else {
      failApiCall();
    }
  }, []);

  const navigate = useNavigate();

  const failApiCall = async () => {
    let formdata = new FormData();
    formdata.append("order_id", order_id);
    await orderFail(formdata)
      .then((response) => {
        setTimeout(() => {
          localStorage.removeItem("order_id");
          toast(response?.data?.message, { type: "error" });
          navigate("/cart", { replace: true });
        }, 3000);
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error?.response?.data?.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };
  return (
    <section className="mt-5">
      <MDBContainer className="py-5  mt-5">
        <MDBCardBody className="p-4" style={{ marginTop: "66px" }}>
          <div class="row justify-content-center">
            <div class="col-md-5">
              <div
                class="message-box _success _failed"
                style={{ backgroundColor: "#202020", marginBottom: "4px" }}
              >
                <i class="fa fa-times-circle" aria-hidden="true"></i>
                <h2> Your payment failed </h2>
                <p> Please try again later </p>
                {/* <div className="form-group mt-5">
                  <button
                    className="theme-btn btn-style-one clearfix"
                    onClick={() => {
                      navigate("/cart", { replace: true });
                    }}
                  >
                    <span className="btn-wrap">
                      <span className="text-one">Back to Home</span>
                      <span className="text-two">Back to Home</span>
                    </span>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </MDBCardBody>
      </MDBContainer>
    </section>
  );
};

export default FailPage;
