import React from "react";
import Reservation from "../Allmenus/FirstMenu/Reservation";
import Bredcrumb from "../Bredcrumb/Main";
import Img from "../../assets/images/background/catering.jpg";
import Menusection from "../Menhome/Menusection";
import { Link } from "react-router-dom";
import menu from "../../assets/sg_menu.pdf";

function Catering() {
  return (
    <>
      <Bredcrumb subtitle="Catering" title="DELICIOUS & AMAZING" Img={Img} />
      {/* <Menusection /> */}
      <div className="auto-container mt-5 mb-5 ">
        <div className="title-box centered mt-5 mb-5">
          {/* <div className="pattern-image">
            <img
              src={require("../../assets/images/icons/separator.svg").default}
              alt="mySvgImage"
            />
          </div> */}

          <h5>
            Whether you are planning a formal occasion at a catering hall, a
            casual get-together at your home, or a business/corporate meeting in
            your office, we will be happy to cater your special event and take
            care of every detail for you.
          </h5>
          <h5 className="mt-5">
            Spice Grill Parsippany offers an extensive range of outside catering
            services to match you and your guests tastes and your budget, and
            our staff will help you plan your menu. Spice Grill is known for its
            excellent service and mouth-watering, freshly prepared Indian food.
            We will suggest items, depending on the size of your party, and be
            sure that all of your favorite dishes are prepared exactly to your
            taste.
          </h5>
          <h5 className="mt-5">
            To discuss the details of your upcoming party or event, fill out the
            form below and our manager or one of our team will contact you
            shortly. Or call is during business hours at
            <u className="m-2">973-882-4646</u>
            to speak with someone today.
          </h5>
          <h5 className="mt-5">
            We look forward to serving you at your home or place of business
            with the Spice Grill’s finest faire.
          </h5>
          <a href={menu} without rel="noopener noreferrer" target="_blank">
            <button className="theme-btn btn-style-one clearfix mt-5">
              <span className="btn-wrap">
                <span className="text-one">View Menu</span>
                <span className="text-two">View Menu</span>
              </span>
            </button>
          </a>
        </div>
      </div>
      <Reservation />
    </>
  );
}

export default Catering;
