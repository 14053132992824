import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { object, ref, string } from "yup";
import { createAccount, getAccountDetails } from "../../services/AuthService";

const AccountDetails = () => {
  const [accountData, setaccountData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let accData = localStorage.getItem("userData");
    accData = JSON.parse(accData);
    await getAccountDetails(accData?.clover_id)
      .then((response) => {
        setaccountData(response?.data?.data);
        setFieldValue("firstname", response?.data?.data?.firstName);
        setFieldValue("lastname", response?.data?.data?.lastName);
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error?.response?.data?.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const RegisterSchema = object().shape({
    firstname: string()
      .required("Please enter first name.")
      .trim("Please enter first name."),

    lastname: string()
      .required("Please enter last name.")
      .trim("Please enter last name."),

    mobile: string()
      .required("Please enter contact number.")
      .trim("Please enter contact number."),

    email: string()
      .required("Please enter email.")
      .trim("Please enter email.")
      .email("Invalid Email Format. Please try again."),

    password: string()
      .required("Please enter password.")
      .trim("Please enter password."),

    confirm_password: string()
      .required("Please enter confirm password.")
      .trim("Please enter confirm password.")
      .oneOf(
        [ref("password")],
        "Password and confirm password does not match."
      ),
  });

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    validationSchema: RegisterSchema,

    initialValues: {
      firstname: accountData?.firstName,
      lastname: accountData?.lastName,
      email: accountData?.email,
      mobile: accountData?.mobile,

      postalcode: accountData?.postalcode,
      city: accountData?.city,
      state: accountData?.state,
      country: accountData?.country,
    },
    onSubmit: async (values, { resetForm }) => {
      // await createAccount(values)
      //   .then((response) => {
      //     setisLoading(false);
      //     toast("Customer Register Successfully", { type: "success" });
      //     navigate("/login");
      //   })
      //   .catch((error) => {
      //     setisLoading(false);
      //     if (error?.response?.status == 422)
      //       toast(error.response.data.error, { type: "error" });
      //     else if (error?.response?.status == 500)
      //       toast(error.response.data.message, { type: "error" });
      //     else toast("Something went wrong", { type: "error" });
      //   });
    },

    onReset: () => {},
  });


  return (
    <div className="auto-container" style={{ marginTop: "230px" }}>
      <div
        // className="c-page-form-box"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="loc-block col-lg-6 col-md-12 col-sm-12">
          <div className="title-box centered" style={{ marginBottom: "40px" }}>
            <h2>Account Details</h2>
          </div>
          <div className="default-form reservation-form">
            <form
              onSubmit={(e) => {
                e?.preventDefault();
                handleSubmit();
              }}
            >
              <div className=" row clearfix">
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="text"
                      name="firstname"
                      placeholder="First Name*"
                      onBlur={handleBlur}
                      disabled={true}
                      onChange={handleChange}
                      value={accountData.firstName}
                      style={{
                        borderColor:
                          touched?.firstname && errors?.firstname && "red",
                      }}
                    />
                  </div>
                  {touched?.firstname && errors?.firstname && (
                    <p className="error-message">{errors.firstname}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="text"
                      name="lastname"
                      placeholder="Last Name*"
                      disabled={true}
                      // required=""
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={accountData?.lastName}
                      style={{
                        borderColor:
                          touched?.lastname && errors?.lastname && "red",
                      }}
                    />
                  </div>
                  {touched?.lastname && errors?.lastname && (
                    <p className="error-message">{errors.lastname}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="text"
                      name="email"
                      placeholder="Email*"
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={accountData.email}
                      style={{
                        borderColor: touched?.email && errors?.email && "red",
                      }}
                      // required=""
                    />
                  </div>
                  {touched?.email && errors?.email && (
                    <p className="error-message">{errors.email}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="text"
                      name="mobile"
                      placeholder="Phone*"
                      onBlur={handleBlur}
                      disabled={true}
                      onChange={handleChange}
                      value={accountData?.phone_number}
                      style={{
                        borderColor: touched?.mobile && errors?.mobile && "red",
                      }}
                      // required=""
                    />
                  </div>
                  {touched?.mobile && errors?.mobile && (
                    <p className="error-message">{errors.mobile}</p>
                  )}
                </div>

                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <select
                      placeholder="Country"
                      disabled={true}
                      value={accountData?.country}
                    >
                      <option>{accountData?.country}</option>
                    </select>
                    <span className="arrow-icon far fa-angle-down"></span>
                  </div>
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <select
                      placeholder="State"
                      disabled={true}
                      value={accountData?.state}
                    >
                      <option>{accountData?.state}</option>
                    </select>
                    <span className="arrow-icon far fa-angle-down"></span>
                  </div>
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="text"
                      name="city"
                      disabled={true}
                      placeholder="city "
                      value={accountData.city}
                    />
                  </div>
                </div>

                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="number"
                      name="postalcode"
                      disabled={true}
                      placeholder="Zip "
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={accountData.postalcode}
                      style={{
                        borderColor:
                          touched?.postalcode && errors?.postalcode && "red",
                      }}
                    />
                  </div>
                  {touched?.postalcode && errors?.postalcode && (
                    <p className="error-message">{errors.postalcode}</p>
                  )}
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 ">
                  <div className="field-inner">
                    <textarea
                      name="address"
                      disabled={true}
                      placeholder="Address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={accountData?.addreess}
                    ></textarea>
                  </div>
                </div>
                {/* <div className="form-group">
                  <button
                    type="submit"
                    className="theme-btn btn-style-one clearfix"
                  >
                    <span className="btn-wrap">
                      <span className="text-one">Register</span>
                      <span className="text-two">Register</span>
                    </span>
                  </button>
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
