import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import img from "../../assets/images/food-image/category-vegetarian-kabab.jpg";
import { Link, useNavigate } from "react-router-dom";
import { getAccountDetails } from "../../services/AuthService";
import { toast } from "react-toastify";
import {
  addOrder,
  cartClearApi,
  retriveCartItem,
} from "../../services/ItemService";
import { loadStripe } from "@stripe/stripe-js";

const Checkout = () => {
  const navigate = useNavigate();
  const [accountData, setaccountData] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [totalTaxAmount, settotalTaxAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [cartData, setcartData] = useState([]);

  useEffect(() => {
    getData();
    getCartData();
  }, []);

  const getCartData = async () => {
    await retriveCartItem()
      .then((response) => {
        settotalTaxAmount(response?.data?.data[0]?.total_tax);
        setSubtotal(response?.data?.data[0]?.sub_total);
        setTotal(response?.data?.data[0]?.grand_total);
        setcartData(response?.data?.data[0]?.data);
      })
      .catch((error) => {
        console.log(error, "<<<err");
        if (error?.response?.status == 422)
          toast(error?.response?.data?.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const getData = async () => {
    let accData = localStorage.getItem("userData");
    accData = JSON.parse(accData);
    await getAccountDetails(accData?.clover_id)
      .then((response) => {
        setaccountData(response?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error?.response?.data?.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const deleteCalled = async () => {
    let formdata = new FormData();
    let array = [];
    for (let a of cartData) {
      array.push(a?.item_id);
    }
    formdata.append("item_id", array?.join());

    await cartClearApi(formdata)
      .then((response) => {
        navigate("/");
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error?.response?.data?.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };
  // const openLink = (url) => window.open(url, "")?.focus();

  const openLink=(url)=>{
    window.location.replace(url)
  }

  const addOrderToCart = async () => {
    let formdata = new FormData();
    formdata.append("sub_total", subtotal);
    formdata.append("grand_total", total);
    formdata.append("total_tax", totalTaxAmount);

    // for (let i = 0; i < cartData.length; i++) {
    formdata.append("order_item", JSON.stringify(cartData));
    // }
    await addOrder(formdata)
      .then((response) => {
        // deleteCalled();
        localStorage.setItem("order_id", response?.data?.data?.order_id);
        openLink(response?.data?.data?.payment_link);

        // toast(response?.data?.message, { type: "success" });
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error?.response?.data?.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <div style={{ marginTop: "230px" }}>
      <div
        // className="c-page-form-box"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="mb-4">
          <div className="title-box centered" style={{ marginBottom: "40px" }}>
            <h2>Checkout</h2>
            <h5 className="mt-1">
              You have {cartData?.length} items in your cart
            </h5>
          </div>

          <section
            className=""
            style={{ backgroundColor: "#202020", marginBottom: "4px" }}
          >
            <MDBContainer className="py-5 h-100 ">
              <MDBCardBody className="p-4">
                <MDBRow>
                  <MDBCol lg="7">
                    <MDBTypography tag="h5">
                      <Link to="/menuone" className="">
                        <MDBIcon fas icon="long-arrow-alt-left me-2 mb-3" />{" "}
                        Continue shopping
                      </Link>
                    </MDBTypography>

                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div>
                        <p className="mb-1">Shopping cart</p>
                      </div>
                    </div>
                    <div style={{ height: "730px", overflow: "scroll" }}>
                      {cartData?.map((x) => (
                        <MDBCard
                          className="mb-3 "
                          style={{ backgroundColor: "black" }}
                        >
                          <MDBCardBody>
                            <div className="d-flex justify-content-between m-2">
                              <div className="d-flex flex-row align-items-center">
                                <div>
                                  <MDBCardImage
                                    src={img}
                                    fluid
                                    className="rounded-3"
                                    style={{ width: "65px" }}
                                    alt="Shopping item"
                                  />
                                </div>
                                <div className="mx-3">
                                  <MDBTypography tag="h5">
                                    {x?.name}
                                  </MDBTypography>
                                  {/* <p className="small mb-0">Desc</p> */}
                                </div>
                              </div>
                              <div className="d-flex flex-row align-items-center">
                                <div
                                // style={{ width: "50px" }}
                                >
                                  <MDBTypography
                                    tag="h5"
                                    className="fw-normal mb-0"
                                    style={{
                                      marginRight: "30px",
                                    }}
                                  >
                                    ${x?.price} x {x?.qty}
                                  </MDBTypography>
                                </div>
                                <div
                                  style={{ width: "80px", marginRight: "4px" }}
                                >
                                  <MDBTypography tag="h5" className="mb-0">
                                    ${(x?.price * x?.qty)?.toFixed(2)}
                                  </MDBTypography>
                                </div>
                                {/* <a href="#!" style={{ color: "#cecece" }}>
                              <MDBIcon fas icon="trash-alt" />
                            </a> */}
                              </div>
                            </div>
                          </MDBCardBody>
                        </MDBCard>
                      ))}
                    </div>
                  </MDBCol>

                  <MDBCol lg="5">
                    <MDBCard
                      className=" text-white rounded-3"
                      style={{ backgroundColor: "black", marginTop: "90px" }}
                    >
                      <div className="d-flex justify-content-between align-items-center m-4 m-2">
                        <MDBTypography tag="h5" className="mb-0">
                          Account details
                        </MDBTypography>
                      </div>

                      <MDBCardBody>
                        {/* <a href="#!" type="submit" className="text-white">
                          <MDBIcon fab icon="cc-mastercard fa-2x me-2" />
                        </a>
                        <a href="#!" type="submit" className="text-white">
                          <MDBIcon fab icon="cc-visa fa-2x me-2" />
                        </a>
                        <a href="#!" type="submit" className="text-white">
                          <MDBIcon fab icon="cc-amex fa-2x me-2" />
                        </a>
                        <a href="#!" type="submit" className="text-white">
                          <MDBIcon fab icon="cc-paypal fa-2x me-2" />
                        </a> */}

                        <form className="">
                          <MDBRow className="mb-4">
                            <MDBCol md="6">
                              <span>Firstname</span>
                              <MDBInput
                                // className="mb-4"
                                value={accountData?.firstName}
                                type="text"
                                size="lg"
                                placeholder="First Name"
                                contrast
                              />
                            </MDBCol>
                            <MDBCol md="6">
                              <span>Lastname</span>

                              <MDBInput
                                // className="mb-4"
                                value={accountData?.lastName}
                                type="text"
                                size="lg"
                                placeholder="Last Name"
                                contrast
                              />
                            </MDBCol>
                          </MDBRow>
                          <span>Email</span>

                          <MDBInput
                            className="mb-4"
                            type="text"
                            size="lg"
                            placeholder="Email"
                            value={accountData?.email}
                            contrast
                          />

                          <span>Number</span>

                          <MDBInput
                            className="mb-4"
                            type="text"
                            size="lg"
                            placeholder="Phone"
                            value={accountData?.phone_number}
                            contrast
                          />
                        </form>

                        <hr />

                        <div className="d-flex justify-content-between">
                          <p className="mb-2">Subtotal</p>
                          <p className="mb-2">${subtotal}</p>
                        </div>

                        <div className="d-flex justify-content-between">
                          <p className="mb-2">Tax</p>
                          <p className="mb-2">${totalTaxAmount}</p>
                        </div>

                        <div className="d-flex justify-content-between">
                          <p className="mb-2">Total(Incl. taxes)</p>
                          <p className="mb-2">${total}</p>
                        </div>

                        <Link
                          onClick={() => {
                            // addOrderToCart();
                            addOrderToCart();
                          }}
                          className="theme-btn  btn-style-one clearfix "
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <span className="btn-wrap">
                            <span className="text-one">
                              {" "}
                              <span>
                                Checkout{" "}
                                <i className="fas fa-long-arrow-alt-right ms-2"></i>
                              </span>
                            </span>
                            <span className="text-two">
                              <span>
                                Checkout{" "}
                                <i className="fas fa-long-arrow-alt-right ms-2"></i>
                              </span>
                            </span>
                          </span>
                        </Link>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBContainer>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
