import React from 'react'
import Reservation from '../Allmenus/FirstMenu/Reservation';
import Specialmenu from '../Allmenus/Menmenu/Specialmenu';
import Bredcrumb from '../Bredcrumb/Main'
import Img from "../../assets/images/background/menu_banner.jpg";
import { useParams } from 'react-router-dom';

function ItemDetails() {
  const params=useParams()
  let str = params?.name?.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  });
  str = str?.replace(/-/g, " ");
  console.log(str)
  return (
    <>
      <Bredcrumb subtitle={str} title="DELICIOUS & AMAZING" Img={Img} />
      <Specialmenu id={params?.id} />
      {/* <Reservation /> */}
    </>
  )
}

export default ItemDetails