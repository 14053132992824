import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Bredcrumb from "../Bredcrumb/Main";

import Img from "../../assets/images/food-image/category-vegetarian-kabab.jpg";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBIcon,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import {
  addItemToCart,
  retriveWishList,
  wishlistClearApi,
} from "../../services/ItemService";
import { toast } from "react-toastify";
import ConfirmationPopupModal from "../ConfirmationPopupModal/Main";

const Favourites = () => {
  const [data, setdata] = useState([]);
  const [isPopup, setisPopup] = useState(false);
  const [deletePopUp, setdeletePopUp] = useState(false);
  const [addToCartPopup, setaddToCartPopup] = useState(false);
  const [itemId, setitemId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  function removeDuplicates(books) {
    // Create an array of objects

    const ids = books.map(({ item_id }) => item_id);
    const filtered = books.filter(
      ({ item_id }, index) => !ids.includes(item_id, index + 1)
    );
    return filtered;
  }

  const getData = async () => {
    await retriveWishList()
      .then((response) => {
        if (response?.data?.data[0]?.data) {
          setdata(removeDuplicates(response?.data?.data[0]?.data));
        } else {
          setdata([]);
        }
      })
      .catch((error) => {
        console.log(error, "<<<err");
        if (error?.response?.status == 422)
          toast(error?.response?.data?.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const deleteCalled = async (id, isMessage, deleteAll) => {
    let formdata = new FormData();
    if (deleteAll) {
      let array = [];
      for (let a of data) {
        array?.push(a?.item_id);
      }
      formdata.append("item_id", array?.join());
    } else {
      formdata.append("item_id", id);
    }
    await wishlistClearApi(formdata)
      .then((response) => {
        if (response?.data?.data[0]?.data) {
          setdata(removeDuplicates(response?.data?.data[0]?.data));
        } else {
          setdata([]);
        }
        setitemId("");
        setisPopup(false);
        setaddToCartPopup(false);
        setdeletePopUp(false);

        if (!isMessage) {
          toast(response?.data?.message, { type: "success" });
        }
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error?.response?.data?.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const addToCartCalled = async (id) => {
    let formdata = new FormData();
    formdata.append("item_id", id);
    formdata.append("item_qty", "1");

    await addItemToCart(formdata)
      .then((response) => {
        toast(response?.data?.message, { type: "success" });
        setitemId("");
        setaddToCartPopup(false);
        deleteCalled(id, true);
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error?.response?.data?.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };
  return (
    <>
      <Bredcrumb subtitle="Favorites" title="DELICIOUS & AMAZING" Img={Img} />
      <div className="container mb-80 mt-50">
        <div className="row">
          <div className="col-lg-12 mb-40">
            <h1 className="heading-2 mb-10 mt-5">Your Favorites</h1>
            <div className="d-flex justify-content-between">
              <h6 className="mb-5">
                There are <span className="text-brand"> {data?.length} </span>{" "}
                items in your favorites
              </h6>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div
              className="table-responsive shopping-summery"
              style={{
                height: "670px",
              }}
            >
              {data?.map((x) => (
                <MDBCard
                  className="rounded-3 mb-4"
                  style={{ backgroundColor: "black" }}
                >
                  <MDBCardBody className="p-4">
                    <MDBRow className="justify-content-between align-items-center">
                      <MDBCol md="2" lg="2" xl="2">
                        <MDBCardImage
                          className="rounded-3"
                          fluid
                          src={Img}
                          alt="Cotton T-shirt"
                        />
                      </MDBCol>
                      <MDBCol md="3" lg="3" xl="3">
                        <p className="lead fw-normal mb-2">{x?.name}</p>
                        {/* <p>
                        <span className="text-muted">Size: </span>M{" "}
                        <span className="text-muted">Color: </span>Grey
                      </p> */}
                      </MDBCol>
                      <MDBCol
                        md="3"
                        lg="3"
                        xl="3"
                        className="d-flex align-items-center justify-content-around "
                      >
                        <Link
                          className="theme-btn  btn-style-one clearfix "
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setitemId(x?.item_id);
                            setaddToCartPopup(true);
                          }}
                        >
                          <span className="btn-wrap">
                            <span className="text-one"> Add to cart</span>
                            <span className="text-two"> Add to cart</span>
                          </span>
                        </Link>
                      </MDBCol>
                      <MDBCol md="3" lg="2" xl="2" className="offset-lg-1 ">
                        <MDBTypography tag="h5" className="mb-0">
                          ${x?.price}
                        </MDBTypography>
                      </MDBCol>
                      <MDBCol md="1" lg="1" xl="1" className="text-end ">
                        <a
                          // href=""
                          className="text-danger"
                          onClick={(e) => {
                            e?.preventDefault();
                            setdeletePopUp(true);
                            setitemId(x?.item_id);
                          }}
                        >
                          <MDBIcon fas icon="trash text-danger" size="lg" />
                        </a>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              ))}
            </div>
            <div className="divider-2 mb-30" />

            <h6
              className="text-brand mb-2 curser-hover pe-auto"
              onClick={() => {
                // setisAddressPopup(true);
              }}
            >
              <a
                href=""
                onClick={(e) => {
                  e?.preventDefault();
                }}
              ></a>
            </h6>
            <div
              className="cart-action  d-flex gap-3 mb-5 mt-4"
              style={{ justifyContent: "space-between", flexDirection: "row" }}
            >
              <Link
                className="theme-btn  btn-style-one clearfix "
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setisPopup(true);
                }}
              >
                <span className="btn-wrap">
                  <span className="text-one"> Empty Cart</span>
                  <span className="text-two"> Empty Cart</span>
                </span>
              </Link>

              <Link
                className="theme-btn  btn-style-one clearfix "
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                to="/menuone"
              >
                <span className="btn-wrap">
                  <span className="text-one"> Explore Menu</span>
                  <span className="text-two"> Explore Menu</span>
                </span>
              </Link>
            </div>
          </div>
        </div>
        <ConfirmationPopupModal
          title={"Confirmation Popup"}
          content={`Are you Sure you want to empty your favourites ?`}
          show={isPopup}
          setShow={setisPopup}
          confirmClicked={() => {
            deleteCalled("", false, true);
          }}
        />
        <ConfirmationPopupModal
          title={"Confirmation Popup"}
          content={`Are you Sure you want to add this item to cart ?`}
          show={addToCartPopup}
          setShow={setaddToCartPopup}
          confirmClicked={() => {
            addToCartCalled(itemId);
          }}
        />
        <ConfirmationPopupModal
          title={"Confirmation Popup"}
          content={`Are you Sure you want to delete this item from favourites ?`}
          show={deletePopUp}
          setShow={setdeletePopUp}
          confirmClicked={() => {
            deleteCalled(itemId);
          }}
        />
      </div>
    </>
  );
};

export default Favourites;
