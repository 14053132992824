import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import img from "../../../assets/images/food-image/category-vegetarian-kabab.jpg";
import {
  // MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBIcon,
  // MDBInput,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import {
  addItemToCart,
  cartClearApi,
  retriveCartItem,
} from "../../../services/ItemService";
import _ from "lodash";
import ConfirmationPopupModal from "../../ConfirmationPopupModal/Main";

const Cart = () => {
  const [subtotal, setSubtotal] = useState(0);
  const [totalTaxAmount, settotalTaxAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [cartData, setcartData] = useState([]);
  const [isPopup, setisPopup] = useState(false);
  const [deletePopUp, setdeletePopUp] = useState(false);
  const [proceedPopup, setproceedPopup] = useState(false);
  const [itemId, setitemId] = useState("");

  //   const { cart_qty, setCartQty } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  const getData = async () => {
    await retriveCartItem()
      .then((response) => {
        settotalTaxAmount(response?.data?.data[0]?.total_tax);
        setSubtotal(response?.data?.data[0]?.sub_total);
        setTotal(response?.data?.data[0]?.grand_total);
        setcartData(response?.data?.data[0]?.data);
      })
      .catch((error) => {
        console.log(error, "<<<err");
        if (error?.response?.status == 422)
          toast(error?.response?.data?.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const deleteCalled = async (id, deleteAll) => {
    let formdata = new FormData();
    if (deleteAll) {
      let array = [];
      for (let a of cartData) {
        array.push(a?.item_id);
      }
      formdata.append("item_id", array?.join());
    } else {
      formdata.append("item_id", id);
    }
    await cartClearApi(formdata)
      .then((response) => {
        setisPopup(false);
        setdeletePopUp(false);
        setitemId("");
        settotalTaxAmount(response?.data?.data[0]?.total_tax);
        setSubtotal(response?.data?.data[0]?.sub_total);
        setTotal(response?.data?.data[0]?.grand_total);
        setcartData(response?.data?.data[0]?.data);
        toast(response?.data?.message, { type: "success" });
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error?.response?.data?.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const addToCartCalled = async (value, id) => {
    let formdata = new FormData();
    formdata.append("item_id", id);
    formdata.append("item_qty", value || 0);

    await addItemToCart(formdata)
      .then((response) => {
        settotalTaxAmount(response?.data?.data[0]?.total_tax);
        setSubtotal(response?.data?.data[0]?.sub_total);
        setTotal(response?.data?.data[0]?.grand_total);
        setcartData(response?.data?.data[0]?.data);
        toast(response?.data?.message, { type: "success" });
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error?.response?.data?.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <div className="container mb-80 mt-50">
      <div className="row">
        <div className="col-lg-12 mb-40">
          <h1 className="heading-2 mb-10 mt-5">Your Cart</h1>
          <div className="d-flex justify-content-between">
            <h6 className="mb-3">
              There are{" "}
              <span className="text-brand "> {cartData?.length || 0} </span>{" "}
              products in your cart
            </h6>
          </div>
          {/* <>
          {total < 199 && (
            <div
              className="alert alert-danger mt-3 mb-5"
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "6px",
                marginRight: "5px",
              }}
              role="alert"
            >
              <i className="fi-rs-cross-circle ml-3" />
              Minimum Order value $200 to proceed with your order!
            </div>
          )}
          </> */}
          {_.isEmpty(cartData) && (
            <div
              className="alert mt-3 mb-5"
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                backgroundColor: "var(--main-color)",
                gap: "6px",
                color: "black",
                marginRight: "5px",
                fontSize: "20px",
              }}
              role="alert"
            >
              <i className="fi-rs-cross-circle ml-3 " />
              No items avalaible in your cart
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div
            className="table-responsive shopping-summery"
            style={{
              height: "670px",
              // backgroundColor: "black",
            }}
          >
            {cartData?.map((x) => (
              <MDBCard
                className="rounded-3 mb-4"
                style={{ backgroundColor: "black" }}
              >
                <MDBCardBody className="p-4">
                  <MDBRow className="justify-content-between align-items-center">
                    <MDBCol md="1" lg="1" xl="1">
                      <MDBCardImage
                        className="rounded-3"
                        fluid
                        src={img}
                        alt="Cotton T-shirt"
                      />
                    </MDBCol>
                    <MDBCol md="2" lg="2" xl="2">
                      <p className="lead fw-normal mb-2">{x?.name} </p>
                      {/* <p>
                        <span className="text-muted">Size: </span>M{" "}
                        <span className="text-muted">Color: </span>Grey
                      </p> */}
                    </MDBCol>
                    <MDBCol
                      md="3"
                      lg="3"
                      xl="2"
                      className="d-flex align-items-center justify-content-around"
                    >
                      <div
                        className="add-to-cart mt-2 mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <i
                          className="icon far fa-minus"
                          onClick={() => {
                            addToCartCalled(Number(x?.qty - 1), x?.item_id);
                          }}
                        ></i>
                        <input
                          className="input-cart"
                          value={x?.qty}
                          type="number"
                          onChange={(e) => {
                            addToCartCalled(e?.target?.value, x?.item_id);
                          }}
                        />
                        <i
                          className="icon far fa-plus"
                          onClick={() => {
                            addToCartCalled(
                              Number(Number(x?.qty) + 1),
                              x?.item_id
                            );
                          }}
                        ></i>
                      </div>
                    </MDBCol>
                    <MDBCol md="1" lg="1" xl="1" className="offset-lg-1">
                      <MDBTypography tag="h5" className="mb-0">
                        ${x?.price}
                      </MDBTypography>
                    </MDBCol>
                    <MDBCol md="2" lg="2" xl="2" className="offset-lg-1">
                      <MDBTypography tag="h5" className="mb-0">
                        ${(x?.price * x?.qty)?.toFixed(2)}
                      </MDBTypography>
                    </MDBCol>
                    <MDBCol md="1" lg="1" xl="1" className="text-end">
                      <a
                        onClick={(e) => {
                          e?.preventDefault();

                          setitemId(x?.item_id);
                          setdeletePopUp(true);
                        }}
                        className="text-danger"
                      >
                        <MDBIcon fas icon="trash text-danger" size="lg" />
                      </a>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            ))}
          </div>
          <div className="divider-2 mb-30" />

          <div
            className="cart-action  d-flex gap-3 mb-3"
            style={{ justifyContent: "space-between", flexDirection: "row" }}
          >
            <Link
              className="theme-btn  btn-style-one clearfix "
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => {
                setisPopup(true);
              }}
            >
              <span className="btn-wrap">
                <span className="text-one"> Empty Cart</span>
                <span className="text-two"> Empty Cart</span>
              </span>
            </Link>

            <Link
              className="theme-btn  btn-style-one clearfix "
              to="/menuone"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span className="btn-wrap">
                <span className="text-one"> Explore Menu</span>
                <span className="text-two"> Explore Menu</span>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="cart m-4">
        <div className="col-lg-4">
          <div
            className="border p-md-4 cart-totals ml-12"
            style={{ backgroundColor: "black" }}
          >
            <div className="table-responsive">
              <table className="table no-border">
                <tbody>
                  <tr>
                    <td className="cart_total_label">
                      <h6 className="">Subtotal</h6>
                    </td>
                    <td className="cart_total_amount">
                      <h4 className="text-brand text-end">${subtotal || 0}</h4>
                    </td>
                  </tr>

                  <tr>
                    <td className="cart_total_label">
                      <h6 className="">Tax</h6>
                    </td>
                    <td className="cart_total_amount">
                      <h4 className="text-brand text-end">
                        ${totalTaxAmount || 0}
                      </h4>
                    </td>
                  </tr>
                  {/* <tr>
                  <td scope="col" colSpan={2}>
                    <div className="divider-2 mt-10 mb-10" />
                  </td>
                </tr>
                <tr>
                  <td className="cart_total_label">
                    <h6 className="">Shipping</h6>
                  </td>
                  <td className="cart_total_amount">
                    <h5 className="text-heading text-end">Free </h5>
                  </td>
                </tr>
                <tr>
                  <td className="cart_total_label">
                    <h6 className="">Estimate for</h6>
                  </td>
                  <td className="cart_total_amount">
                    <h5 className="text-heading text-end">United Kingdom </h5>
                  </td>
                </tr> */}
                  <tr>
                    <td scope="col" colSpan={2}>
                      <div className="divider-2 mt-10 mb-10" />
                    </td>
                  </tr>
                  <tr>
                    <td className="cart_total_label">
                      <h6 className="">Grand total</h6>
                    </td>
                    <td className="cart_total_amount">
                      <h4 className="text-brand text-end">${total || 0}</h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <a
              // to={total > 199 && "/checkout"}
              onClick={() => {
                // setproceedPopup(true);
                // if (total < 199) {
                //   toast(
                //     "Minimum Order value $200 to proceed with your order!",
                //     { type: "error" }
                //   );
                // } else {
                navigate("/checkout");
                // }
              }}
              className="theme-btn  btn-style-one clearfix "
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span className="btn-wrap">
                <span className="text-one">
                  {" "}
                  <i className="icon far fa-shopping-cart"></i> Proceed
                </span>
                <span className="text-two">
                  {" "}
                  <i className="icon far fa-shopping-cart"></i> Proceed
                </span>
              </span>
            </a>
          </div>
          <ConfirmationPopupModal
            title={"Confirmation Popup"}
            content={`Are you Sure you want to empty your cart ?`}
            show={isPopup}
            setShow={setisPopup}
            confirmClicked={() => {
              deleteCalled("", true);
            }}
          />
          <ConfirmationPopupModal
            title={"Confirmation Popup"}
            content={`Are you Sure you want to delete this item from your cart ?`}
            show={deletePopUp}
            setShow={setdeletePopUp}
            confirmClicked={() => {
              deleteCalled(itemId);
            }}
          />
          <ConfirmationPopupModal
            title={"Confirmation Popup"}
            content={`Are you Sure you want to Proceed with your order ?`}
            show={proceedPopup}
            setShow={setproceedPopup}
            confirmClicked={() => {
              navigate("/checkout");
              setproceedPopup(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Cart;
