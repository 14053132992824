import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import img from "../../assets/images/food-image/category-vegetarian-kabab.jpg";
import { Link } from "react-router-dom";

const OrderCart = ({ obj, toggle, settoggle }) => {
  let accData = localStorage.getItem("userData");
  let firstName = JSON.parse(accData)?.first_name;
  return (
    <MDBContainer className="py-5 h-100 ">
      <MDBRow className="justify-content-center align-items-center h-100">
        <MDBCol lg="10" xl="8">
          <MDBCard style={{ borderRadius: "10px", border: "none" }}>
            <MDBCardHeader
              className="px-4 py-5 d-flex justify-content-between  "
              style={{
                backgroundColor: "#202020",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <MDBTypography tag="h5" className="text-white mb-0">
                Thanks for your Order,{" "}
                <span style={{ color: "var(--main-color)" }}>{firstName}</span>!
              </MDBTypography>
              <Link
                className="theme-btn  btn-style-one clearfix "
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={() => {
                  settoggle(false);
                }}
              >
                <span className="btn-wrap">
                  <span className="text-one">
                    <i className="icon far fa-shopping-cart"></i>
                    Back
                  </span>
                  <span className="text-two">
                    <i className="icon far fa-shopping-cart"></i>
                    Back
                  </span>
                </span>
              </Link>
            </MDBCardHeader>
            <MDBCardBody className="p-4" style={{ backgroundColor: "#202020" }}>
              <div className="d-flex justify-content-between align-items-center mb-4">
                {/* <p
                  className="lead fw-normal mb-0"
                  style={{ color: "var(--main-color)" }}
                >
                  Receipt
                </p> */}
                <p className="text-muted mb-0"> Date : {obj?.created_at}</p>
              </div>

              {obj?.order_items?.map((x) => {
                let total = x?.item_price * x?.item_qty;
                return (
                  <MDBCard
                    className="shadow-0  mb-4"
                    style={{ backgroundColor: "black" }}
                  >
                    <MDBCardBody>
                      <MDBRow>
                        <MDBCol md="2">
                          <MDBCardImage src={img} fluid alt="Phone" />
                        </MDBCol>
                        <MDBCol
                          md="2"
                          className="text-center d-flex justify-content-center align-items-center"
                        >
                          <p className="text-muted mb-0">{x?.item_name}</p>
                        </MDBCol>

                        <MDBCol
                          md="3"
                          className="text-center d-flex justify-content-center align-items-center"
                        >
                          <p className="text-muted mb-0 ">
                            Price: ${x?.item_price}
                          </p>
                        </MDBCol>
                        <MDBCol
                          md="3"
                          className="text-center d-flex justify-content-center align-items-center"
                        >
                          <p className="text-muted mb-0 ">Qty: {x?.item_qty}</p>
                        </MDBCol>
                        <MDBCol
                          md="2"
                          className="text-center d-flex justify-content-center align-items-center"
                        >
                          <h5 className="text-muted mb-0 ">${total}</h5>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                );
              })}

              <div className="d-flex justify-content-end pt-2">
                <p className="text-muted mb-0">
                  <span className="fw-bold me-4">Sub Total</span> $
                  {obj?.subtotal}
                </p>
              </div>

              <div className="d-flex justify-content-end pt-2">
                <p className="text-muted mb-0">
                  <span className="fw-bold me-4">Tax</span> ${obj?.total_tax}
                </p>
              </div>

              <div className="d-flex justify-content-end pt-2">
                <p className="text-muted mb-0">
                  <span className="fw-bold me-4">Processing fees</span> $
                  {obj?.processing_fees}
                </p>
              </div>
            </MDBCardBody>
            <MDBCardFooter
              className="border-0 px-4 py-5"
              style={{
                backgroundColor: "var(--main-color)",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <MDBTypography
                tag="h5"
                className="d-flex align-items-center justify-content-end text-uppercase mb-0"
                style={{ color: "black" }}
              >
                Total paid:{" "}
                <span className="h2 mb-0 ms-2">${obj?.total_payment}</span>
              </MDBTypography>
            </MDBCardFooter>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default OrderCart;
