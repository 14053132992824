import { MDBCardBody, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { orderSuccess } from "../../services/ItemService";
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
  let order_id = localStorage.getItem("order_id");
  const navigate = useNavigate();

  useEffect(() => {
    if (!order_id) {
      navigate(-1, { replace: true });
    } else {
      successApiCall();
    }
  }, []);

  const successApiCall = async () => {
    // ;

    let formdata = new FormData();
    formdata.append("order_id", order_id);
    await orderSuccess(formdata)
      .then((response) => {
        setTimeout(() => {
          localStorage.removeItem("order_id");
          toast(response?.data?.message, { type: "success" });
          navigate("/", { replace: true });
        }, 3000);
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error?.response?.data?.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section className="mt-5">
      <MDBContainer className="py-5  mt-5">
        <MDBCardBody className="p-4" style={{ marginTop: "66px" }}>
          <div class="row justify-content-center">
            <div class="col-md-5">
              <div
                class="message-box _success mt-5"
                style={{ backgroundColor: "#202020", marginBottom: "4px" }}
              >
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <h2> Your payment was successful </h2>
                <p>
                  {" "}
                  Thank you for your payment. we will <br />
                  be in contact with more details shortly{" "}
                </p>
                {/* <div className="form-group mt-5">
                  <button
                    className="theme-btn btn-style-one clearfix"
                    onClick={() => {
                      navigate("/", { replace: true });
                    }}
                  >
                    <span className="btn-wrap">
                      <span className="text-one">Back to Home</span>
                      <span className="text-two">Back to Home</span>
                    </span>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </MDBCardBody>
      </MDBContainer>
    </section>
  );
};

export default SuccessPage;
