import Header from "../components/Header/Main";
import Footer from "../components/Footer/Main";
import Home from "../components/Home/Main";
import About from "../components/About/Main";
import Chefs from "../components/Chef/Main";
import Contact from "../components/Contact/Main";
import Menu from "../components/Menu/Main";
import Menuone from "../components/Menu/Menuone";
import OrderHistory from "../components/OrderHistory/Main";
// import Menufour from "../components/Menu/Menufour";
import Book from "../components/Book/Main";
import Hometwo from "../components/Headertwo/Main";
import Login from "../components/Login/Main";
import Register from "../components/Register/Main";
import Cart from "../components/Cart/Main";
import Homethree from "../components/Homethree/Homethree";
import Catering from "../components/Menu/Catering";
import AccountDetails from "../components/AccountDetails/Main";
import Favourites from "../components/Favourites/Main";
import Checkout from "../components/Checkout/Main";
import SuccessPage from "../components/SuccessPage/Main";
import FailPage from "../components/FailPage/Main";
import ItemDetails from "../components/ItemDetails/Main";

const INDEX = "/";
const LOGIN = "/login";
const REGISTER = "/register";
const CART = "/cart";
const HOMETWO = "/hometwo";
const HOMETHREE = "/homethree";
const CATERING = "/catering";
const MENU = "/menu";
const MENUONE = "/menuone";
const CHECKOUT = "/checkout";
const ACCOUNT_DETAILS = "/account-details";
const ORDER_HISTORY = "/order-history";
const FAVOURITES = "/favourites";
const ABOUT_US = "/about";
// const OURCHEFS = "/ourchefs";
const SUCCESS = "/success";
const FAIL = "/fail";
const CONTACT = "/contact";
const RESERVATION = "/reservation";
const ORDER_DETAILS="/menuone/:name/:id"

const route = [
  {
    path: INDEX,
    exact: true,
    private: false,
    page: {
      component: Home,
      title: "Home",
    },
  },
  {
    path: LOGIN,
    exact: true,
    private: false,
    page: {
      component: Login,
      title: "Login",
    },
  },
  {
    path: REGISTER,
    private: false,
    exact: true,
    page: {
      component: Register,
      title: "Register",
    },
  },
  {
    path: CART,
    exact: true,
    private: true,
    page: {
      component: Cart,
      title: "Cart",
    },
  },
  {
    path: CHECKOUT,
    exact: true,
    private: true,
    page: {
      component: Checkout,
      title: "Checkout",
    },
  },
  {
    path: HOMETWO,
    exact: true,
    private: false,
    page: {
      component: Hometwo,
      title: "Hometwo",
    },
  },
  {
    path: HOMETHREE,
    exact: true,
    private: false,
    page: {
      component: Homethree,
      title: "Homethree",
    },
  },
  {
    path: ORDER_DETAILS,
    exact: true,
    private: false,
    page: {
      component:ItemDetails ,
      title: "Order-details",
    },
  },
  {
    path: CATERING,
    exact: true,
    private: false,
    page: {
      component: Catering,
      title: "Catering",
    },
  },
  {
    path: MENU,
    exact: true,
    private: false,
    page: {
      component: Menu,
      title: "Menu",
    },
  },
  {
    path: ACCOUNT_DETAILS,
    exact: true,
    private: true,
    page: {
      component: AccountDetails,
      title: "Account Details",
    },
  },
  {
    path: ORDER_HISTORY,
    exact: true,
    private: true,
    page: {
      component: OrderHistory,
      title: "Order History",
    },
  },
  {
    path: MENUONE,
    exact: true,
    private: false,
    page: {
      component: Menuone,
      title: "Menuone",
    },
  },

  {
    path: SUCCESS,
    exact: true,
    private: true,
    page: {
      component: SuccessPage,
      title: "Success",
    },
  },
  {
    path: FAIL,
    exact: true,
    private: true,
    page: {
      component: FailPage,
      title: "Fail",
    },
  },
  {
    path: ORDER_HISTORY,
    exact: true,
    private: true,
    page: {
      component: OrderHistory,
      title: "Order History",
    },
  },
  {
    path: FAVOURITES,
    exact: true,
    private: true,
    page: {
      component: Favourites,
      title: "Favourites",
    },
  },
  {
    path: CONTACT,
    exact: true,
    private: false,
    page: {
      component: Contact,
      title: "Contact",
    },
  },
  // {
  //   path: OURCHEFS,
  //   exact: true,
  //   private: true,
  //   page: {
  //     component: Chefs,
  //     title: "Chefs",
  //   },
  // },
  {
    path: RESERVATION,
    exact: true,
    private: false,
    page: {
      component: Book,
      title: "Reservation",
    },
  },
  {
    path: ABOUT_US,
    exact: true,
    private: false,
    page: {
      component: About,
      title: "About US",
    },
  },
];

export default route;
