import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { convertToSlug } from "../../utils/constant";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

const ConfirmationPopupModal = ({
  show,
  setShow,
  modalTitle,
  content,
  setaddressId,
  confirmClicked,
  isOk,
  addressNum,
  onClose,
  addressId,
  setaddressNum,
  addressData,
  title,
}) => {
  let data = addressData?.addresses;

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      size={"md"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <>
        {title && (
          <Modal.Header closeButton style={{ backgroundColor: "#202020" }}>
            <Modal.Title id="contained-modal-title-vcenter">
              {title}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body style={{ backgroundColor: "#202020" }}>
          <h4>{modalTitle}</h4>
          <p>{content}</p>
        </Modal.Body>
      </>

      {!isOk ? (
        <Modal.Footer style={{ backgroundColor: "#202020" }}>
          <Link
            onClick={confirmClicked}
            className="theme-btn  btn-style-one clearfix "
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span className="btn-wrap">
              <span className="text-one"> Yes</span>
              <span className="text-two"> Yes</span>
            </span>
          </Link>

          <Link
            onClick={() => setShow(false)}
            className="theme-btn  btn-style-one clearfix "
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span className="btn-wrap">
              <span className="text-one"> No</span>
              <span className="text-two"> No</span>
            </span>
          </Link>
        </Modal.Footer>
      ) : (
        <Modal.Footer>
          <Button className={" "} onClick={confirmClicked}>
            Ok
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ConfirmationPopupModal;
