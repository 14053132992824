import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Header from "../components/Header/Main";
import Footer from "../components/Footer/Main";
import route from "../routes/route";
import { isLogin } from "../utils/constant";

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // let auth_url = ["/login", "/register"];
    if (
      isLogin() &&
      (location.pathname == "/login" || location.pathname == "/register")
    ) {
      navigate("/");
    }

    return () => {};
  });

  return (
    <>
      <Header />

      <Routes>
        {route.map((route, i) => {
          if (location.pathname === route.path && route.private === true) {
            if (isLogin()) {
              return (
                <Route
                  exact
                  path={route.path}
                  key={i}
                  element={<route.page.component />}
                >
                  {" "}
                </Route>
              );
            } else {
              //   return <Navigate to="/login" />;
              return navigate("/");
            }
          } else {
            return (
              <Route
                exact
                path={route.path}
                key={i}
                element={<route.page.component />}
              >
                {" "}
              </Route>
            );
          }
        })}
        <Route path="*" element={<span>no page found</span>}>
          {" "}
        </Route>
      </Routes>
      <Footer></Footer>
    </>
  );
};

export default Layout;
