import React, { useState } from "react";
import { Link } from "react-router-dom";
import AnimatedNumber from "animated-number-react";
import Iframe from "react-iframe";
import bgten from "../../assets/images/background/bg-10.png";
import bgeleven from "../../assets/images/background/bg-11.png";
import signatureone from "../../assets/images/resource/signature-1.png";
import aboutimgone from "../../assets/images/background/dining.jpeg";
import aboutimgtwo from "../../assets/images/background/sg_main.jpeg";

function Aboutsec() {
  const [Video, setVideo] = useState();

  return (
    <>
      <section className="about-section">
        <div className="left-bg">
          <img src={bgten} alt="" title="" />
        </div>
        <div className="right-bg">
          <img src={bgeleven} alt="" title="" />
        </div>
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>who we are</span>
            </div>

            <div className="pattern-image">
              <img
                src={require("../../assets/images/icons/separator.svg").default}
                alt="mySvgImage"
              />
            </div>

            <h3>
              As you enter Spice Grill Restaurant you have arrived at one of the
              premier Indian restaurants in northern NJ. The savory aromas and
              Indian spices entice you – from the luscious appetizers to the
              mouth- watering Tandoori dishes, tasty Biryanis, vegetarian
              specialties and a delicious variety of warm breads. You
              immediately know that you are in for a delightful meal prepared
              with love by our experienced chefs and served by our dedicated
              wait staff.
              {/* A modern restaurant with a menu that will make your mouth water.
              Servicing delicious food{" "}
              <span className="theme_color">since 45 years</span>. Enjoy our
              seasonal menu and experience the beauty of naturalness */}
            </h3>
          </div>
          <div className="row clearfix">
            <div className="about-block content-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="0ms"
              >
                <div className="text">
                  Since 2006 Spice Grill has specialized in classic northern
                  Indian cuisine, pleasing the palettes of the most discerning
                  diners. Our dishes are served in a comfortable and casual
                  setting where everyone is welcome. Recently we have added
                  Indo-Chinese appetizers and main courses to the delight of our
                  customers. Spice Grill is owned and managed by Patel Family
                  and his dedicated team. They capture the true essence of
                  northern Indian cooking and from the first bite to the very
                  last you will enjoy every morsel. Spice Grill has been serving
                  the families and business population of Morris County and
                  surrounding areas. Many diners enjoy our tempting lunch
                  buffet, filled with over fifteen varied and authentic Indian
                  dishes. The Lunch Buffet is served from 11:30 AM to 2:30 PM
                  . When you have a special upcoming event – anniversary,
                  christening, engagement party, graduation, or business meeting
                  – consider having it in our private party room that holds up
                  to 70 people. We also offer off-site catering for business
                  events in your office and private parties in your home. If you
                  prefer, call ahead and order your lunch or dinner to take out.
                </div>
                <div className="signature">
                  <img src={signatureone} alt="" title="" />
                </div>
                <div className="author">
                  Gajinder Singh Chauhan - Owner & General Manager
                </div>
                <div className="video-box">
                  <div className="image">
                    <Link
                      to="https://www.youtube.com/watch?v=ZETY_l3GVQg"
                      className="lightbox-image"
                    >
                      <img src={aboutimgtwo} alt="" />
                    </Link>
                    <Link
                      to="#"
                      className="lightbox-image play-btn"
                      onClick={() => setVideo(true)}
                    >
                      <span className="icon fal fa-play">
                        <i className="ripple"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="about-block image-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="300ms"
              >
                <div className="image">
                  <img src={aboutimgone} alt="" />
                </div>
              </div>
            </div>

            <div className="about-block info-block col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="600ms"
              >
                <ul className="info">
                  <li>
                    <strong>Lunch Time</strong>Tuesday to Sunday <br />
                    11.30 am - 2.30pm
                  </li>
                  <li>
                    <strong>Dinner Time</strong>Tuesday to Sunday <br />
                    5.30 pm - 11.30 pm
                  </li>
                  <li className="separator">
                    <span></span>
                  </li>
                  <li>
                    <strong>Contact Us</strong>
                    <span className="address">
                      111 Route 46 East, Parsippany, NJ 07054
                    </span>{" "}
                    <Link to="tel:973-882-4646">973-882-4646</Link> <br />
                    <Link to="mailto:spicegrillparsippany@gmail.com">
                      spicegrillparsippany@gmail.com
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="fact-counter">
            <div className="row clearfix">
              <div className="fact-block col-lg-3 col-md-6 col-sm-12">
                <div className="inner clearfix">
                  <div className="fact-count">
                    <div className="count-box">
                      <AnimatedNumber
                        className="count-text"
                        value={150}
                        duration={2000}
                        formatValue={(v) => Math.round(v)}
                      />
                      <i>+</i>
                    </div>
                  </div>
                  <div className="fact-title"> order</div>
                </div>
              </div>
              <div className="fact-block col-lg-3 col-md-6 col-sm-12">
                <div className="inner clearfix">
                  <div className="fact-count">
                    <div className="count-box">
                      <AnimatedNumber
                        className="count-text"
                        value={82}
                        duration={1500}
                        formatValue={(v) => Math.round(v)}
                      />
                      <i>+</i>
                    </div>
                  </div>
                  <div className="fact-title">Special Dishes</div>
                </div>
              </div>
              <div className="fact-block col-lg-3 col-md-6 col-sm-12">
                <div className="inner clearfix">
                  <div className="fact-count">
                    <div className="count-box">
                      <AnimatedNumber
                        className="count-text"
                        value={35}
                        duration={1000}
                        formatValue={(v) => Math.round(v)}
                      />
                      <i>+</i>
                    </div>
                  </div>
                  <div className="fact-title">expert chef</div>
                </div>
              </div>
              <div className="fact-block col-lg-3 col-md-6 col-sm-12">
                <div className="inner clearfix">
                  <div className="fact-count">
                    <div className="count-box">
                      <AnimatedNumber
                        className="count-text"
                        value={10}
                        duration={1000}
                        formatValue={(v) => Math.round(v)}
                      />
                      <i>+</i>
                    </div>
                  </div>
                  <div className="fact-title">awards won</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {Video && (
          <div
            className={`fancybox-container fancybox-is-open fancybox-can-drag fancybox-can-drag ${
              Video && "fancybox-is-closing"
            } fancybox-show-toolbar`}
            onClick={() => {
              setVideo(false);
            }}
            role="dialog"
            tabindex="-1"
            id="fancybox-container-9"
            style={{ transitionDuration: "500ms" }}
          >
            <div className="fancybox-bg"></div>
            <div className="fancybox-inner">
              <div className="fancybox-infobar">
                <span data-fancybox-index="">1</span>&nbsp;/&nbsp;
                <span data-fancybox-count="">1</span>
              </div>
              <div className="fancybox-toolbar">
                <button
                  data-fancybox-play=""
                  className="fancybox-button fancybox-button--play"
                  title="Start slideshow"
                  style={{ display: "none" }}
                >
                  <svg viewBox="0 0 40 40">
                    <path d="M13,12 L27,20 L13,27 Z"></path>
                    <path d="M15,10 v19 M23,10 v19"></path>
                  </svg>
                </button>

                <button
                  data-fancybox-fullscreen=""
                  className="fancybox-button fancybox-button--fullscreen"
                  title="Full screen"
                >
                  <svg viewBox="0 0 40 40">
                    <path d="M9,12 h22 v16 h-22 v-16 v16 h22 v-16 Z"></path>
                  </svg>
                </button>
                <button
                  data-fancybox-thumbs=""
                  className="fancybox-button fancybox-button--thumbs"
                  title="Thumbnails"
                  style={{ display: "none" }}
                >
                  <svg viewBox="0 0 120 120">
                    <path d="M30,30 h14 v14 h-14 Z M50,30 h14 v14 h-14 Z M70,30 h14 v14 h-14 Z M30,50 h14 v14 h-14 Z M50,50 h14 v14 h-14 Z M70,50 h14 v14 h-14 Z M30,70 h14 v14 h-14 Z M50,70 h14 v14 h-14 Z M70,70 h14 v14 h-14 Z"></path>
                  </svg>
                </button>
                <button
                  data-fancybox-share=""
                  className="fancybox-button fancybox-button--share"
                  title="Share"
                >
                  <svg viewBox="0 0 40 40">
                    <path d="M6,30 C8,18 19,16 23,16 L23,16 L23,10 L33,20 L23,29 L23,24 C19,24 8,27 6,30 Z"></path>
                  </svg>
                </button>
                <button
                  data-fancybox-close=""
                  onClick={() => setVideo(false)}
                  className="fancybox-button fancybox-button--close"
                  title="Close"
                >
                  <svg viewBox="0 0 40 40">
                    <path d="M10,10 L30,30 M30,10 L10,30" />
                  </svg>
                </button>
              </div>
              <div className="fancybox-navigation">
                <button
                  data-fancybox-prev=""
                  className="fancybox-button fancybox-button--arrow_left"
                  title="Previous"
                >
                  <svg viewBox="0 0 40 40">
                    <path d="M10,20 L30,20 L10,20 L18,28 L10,20 L18,12 L10,20" />
                  </svg>
                </button>
                <button
                  data-fancybox-next=""
                  className="fancybox-button fancybox-button--arrow_right"
                  title="Next"
                >
                  <svg viewBox="0 0 40 40">
                    <path d="M30,20 L10,20 L30,20 L22,28 L30,20 L22,12 L30,20" />
                  </svg>
                </button>
              </div>
              <div className="fancybox-stage">
                <div className="fancybox-slide fancybox-slide--iframe fancybox-slide--video fancybox-slide--current fancybox-slide--complete">
                  <div className="fancybox-content">
                    <Iframe
                      id="fancybox-frame1671797716315"
                      name="fancybox-frame1671797716315"
                      className="fancybox-iframe"
                      frameborder="0"
                      vspace="0"
                      hspace="0"
                      webkitallowfullscreen=""
                      mozallowfullscreen=""
                      allowfullscreen=""
                      allowtransparency="true"
                      src="//www.youtube.com/embed/ZETY_l3GVQg?autoplay=1&amp;autohide=1&amp;fs=1&amp;rel=0&amp;hd=1&amp;wmode=transparent&amp;enablejsapi=1&amp;html5=1"
                      scrolling="no"
                    ></Iframe>
                  </div>
                </div>
              </div>
              <div className="fancybox-caption-wrap">
                <div className="fancybox-caption"></div>
              </div>
            </div>
          </div>
        )}

        <div className="title-box centered">
          <div className="subtitle">
            <span>About Gajinder Singh Chauhan, Owner & General Manager</span>
          </div>

          <div className="pattern-image">
            <img
              src={require("../../assets/images/icons/separator.svg").default}
              alt="mySvgImage"
            />
          </div>

          <h5>
            Gajinder Singh Chauhan, or GS as many affectionately call him, in
            partnership with his wife, Hazel Dias, is the proud owner of Spice
            Grill Restaurant in Parsippany, NJ. For over 13 years, GS has almost
            single-handedly operated and successfully run Spice Grill to the
            delight of thousands of diners in north Jersey. Spice Grill started
            as a dream project of GS, and with a lot of hard work and dedication
            today it is one of the best Indian cuisine restaurants in northern
            NJ. GS began his hospitality career under the banner of the Maurya
            Sheraton Group, where he worked at Takshila French Restaurant and
            gained valuable experience that would set him up for his life’s
            journey ahead. He then joined Bukhara North East Frontier Indian
            Restaurant and continued to learn and experience first-hand how to
            work as a team member and then leader. In all, GS spent nine years
            working and developing his trade at a renowned hospitality
            organization gaining new skills taught by some of the finest people
            in the business. Upon leaving India, GS was hired by two different
            cruise lines first as a bartender and then a wine steward, employed
            by Carnival and Royal Caribbean. He had worked onboard the various
            ships for a total of five years until 1993 when he arrived in the
            United States. Upon arriving in the US, GS was hired as the General
            Manager for Akbar Restaurant in Garden City, NY where he remained
            for 13 years. In 2006 he decided to put his experience and love of
            the restaurant business to the test and opened the Spice Grill in
            Parsippany, NJ, to great reviews. He has successfully owned and
            operated the restaurant since then. GS and the entire staff at Spice
            Grill pride themselves on serving the freshest ingredients, the most
            succulent dishes blended with delightful Indian spices. The
            restaurant has grown and has a huge following of ardent fans who
            return time after time, as well new customers who are delighted by
            the authentic fresh food. GS lives with his wife Hazel, their two
            children, and two lovable dogs, in Morris County.
          </h5>
        </div>
      </section>
    </>
  );
}

export default Aboutsec;
