import React from "react";
import { FadeLoader } from "react-spinners";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "black",
};

const Loader = ({ color, size }) => {
  return (
    <>
      <FadeLoader
        color={color || 'var(--main-color)'}
        loading={true}
        cssOverride={override}
        size={size || 150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};

export default Loader;
